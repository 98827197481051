import React from "react";
import "../styles/NotFound.css";

function NotFound() {
  return (
    <div className="content">
        <h1>404 Not Found</h1>
    </div>
  );
}

export default NotFound;
